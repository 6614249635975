<template>
  <div>
    <div class="content">
      <breadcrumb></breadcrumb>
      <section class="clearfix" style="margin-bottom: 10px">
        <a-button @click="add" type="primary" v-if="priv('f_brand_create')">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t(editType ? '编辑品牌' : '新增品牌') }}
        </a-button>
      </section>
      <div class="form-content" style="">
        <a-row>
          <a-col :span="5">
            <a-input
              allowClear
              v-model="page.brandName"
              :placeholder="$t('请输入') + $t('品牌')"
            ></a-input>
          </a-col>
          <a-col :span="5" style="margin-left: 15px">
            <a-button type="primary" @click="getDataList" v-if="priv('f_brand_list')">
              {{ $t(`查询`) }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="table-content">
        <a-table
          :columns="newColumns"
          :rowKey="
            (record, index) => {
              return index
            }
          "
          bordered
          :pagination="false"
          :data-source="tableData"
        >
          <template slot="brandName" slot-scope="text">
            {{ text }}
          </template>
          <template slot="platform" slot-scope="text, record">
            <span v-if="record.platform !== 'open'">{{ platformMapping[text] }}</span>
            <p
              v-else
              @click="openData(record, record.isOpen)"
              style="text-align: center; margin: 0"
            >
              <a>{{ record.isOpen === 'false' ? $t('展开') : $t('收起') }}</a>
            </p>
          </template>
          <template slot="platformBrandName" slot-scope="text, record">
            <span v-if="record.isEdit">
              <a-select
                show-search
                :value="params.platformBrandId"
                placeholder="input search text"
                style="width: 200px"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="fetchBrandList"
                @change="handleChangeTable"
                @focus="setValue(0)"
              >
                <a-select-option v-for="d in brandList" :key="d.brandId">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </span>
            <span v-else>{{ text }}</span>
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="editable-row-operations">
              <span v-if="record.isEdit">
                <a @click="() => save(record)">{{ $t('保存') }}</a>

                <a @click="() => cancelTable(record)" style="margin-left: 5px">{{ $t('取消') }}</a>
              </span>
              <span v-if="!record.isEdit && priv('f_brand_update')">
                <a @click="() => edit(record)">{{ $t('编辑') }}</a>
              </span>
            </div>
          </template>
        </a-table>
        <div class="table-footer">
          <a-pagination
            style="float: right; margin-top: 15px"
            size="small"
            :total="page.total"
            :current="page.pageNo"
            show-size-changer
            show-quick-jumper
            @change="changeNo"
            @showSizeChange="changeSize"
            :pageSize="page.pageSize"
            :show-total="(total) => `${$t('总计')} ${total} ${$t('条')} `"
          ></a-pagination>
        </div>
      </div>
    </div>

    <a-modal
      width="800px"
      :visible="addVisible"
      :title="$t('新增品牌')"
      @ok="save"
      @cancel="cancel"
    >
      <a-form-model ref="ruleForm" layout="inline" :model="formData" :rules="rules">
        <a-form-model-item ref="brandName" :label="$t('品牌名')" prop="brandName">
          <a-input
            v-model="formData.brandName"
            @blur="
              () => {
                $refs.brandName.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <template v-if="formData.platformBrandReqVO.length">
          <a-form-model-item
            :ref="item.code"
            :label="item.text"
            :prop="item.code"
            v-for="(item, key) in platformList"
            :key="key"
          >
            <a-select
              show-search
              :value="formData.platformBrandReqVO[key].platformBrandId"
              placeholder="input search text"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="fetchBrandList"
              @change="handleChange"
              @focus="setValue(key)"
            >
              <a-select-option v-for="d in brandList" :key="d.brandId">
                {{ d.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </template>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import { message, pagination } from 'ant-design-vue'
export default {
  name: 'brandManage',
  components: { 'a-pagination': pagination },
  data() {
    return {
      formLayout: 'horizontal',
      form: {},
      nowValue: '',
      editType: false,
      tableData: [],
      platformEdit: '',
      params: {
        platformBrandId: '',
        platformBrandName: '',
      },
      brandList: [],
      platform: '',
      platformBrandReqVOList: [],
      page: {
        total: 30,
        brandName: '',
        pageSize: 10,
        pageNo: 1,
      },
      nowCategory: '',
      nowBrandName: '',
      oldTable: [], //存放老数据
      newColumns: [
        {
          title: 'OMS' + this.$t('品牌'),
          dataIndex: 'brandName',
          key: 'brandName',
          width: 170,
          scopedSlots: { customRender: 'brandName' },
          customCell: (record) => {
            if (record.index == '0') {
              return {
                attrs: {
                  rowSpan: record.mergeRowLength,
                },
              }
            } else {
              return {
                style: {
                  display: record.mergeRowLength ? 'none' : undefined,
                },
                attrs: {
                  rowSpan: record.row,
                },
              }
            }
          },
        },
        {
          title: this.$t('平台'),
          dataIndex: 'platform',
          key: 'platform',
          width: 150,
          scopedSlots: { customRender: 'platform' },
          customCell: (record) => {
            if (record.platform == 'open') {
              return {
                style: {
                  // display: record.platform == "open" ? "none" : undefined,
                },
                attrs: {
                  colSpan: record.mergeColLength,
                },
              }
            }
          },
        },
        {
          title: this.$t('品牌'),
          dataIndex: 'platformBrandName',
          key: 'platformBrandName',
          scopedSlots: { customRender: 'platformBrandName' },
          customCell: (record) => {
            return {
              style: {
                display: record.platform == 'open' ? 'none' : undefined,
              },
              attrs: {
                rowSpan: record.row,
              },
            }
          },
        },
        {
          title: this.$t('操作'),
          dataIndex: 'action',
          width: 150,
          key: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: (record) => {
            return {
              style: {
                display: record.platform == 'open' ? 'none' : undefined,
              },
              attrs: {
                rowSpan: record.row,
              },
            }
          },
        },
      ],
      columns: [
        {
          title: this.$t('品牌名'),
          dataIndex: 'brandName',
          key: 'brandName',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Action',
          dataIndex: 'Action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      addVisible: false,
      addLoading: false,
      rules: {
        brandName: [
          {
            required: true,
            message: 'Please input Activity name',
            trigger: 'blur',
          },
        ],
      },
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      disabled: false,
      select: '',
      value: '',
      editList: [],
      formData: {
        brandName: '',
        brandId: '',
        platformBrandReqVO: [],
      },
    }
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapGetters(['platformMapping']),
    platformList() {
      return this.$store.state.nowPlatformList
    },
  },
  watch: {
    platformList() {
      this.init()
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.formData.platformBrandReqVO = []
        this.platformList.forEach((item, index) => {
          this.formData.platformBrandReqVO.push({
            index: index,
            platform: item.code,
            platformBrandId: '',
            platformBrandName: '',
          })
        })
      })
    },
    //修改保存
    saveBrandName(value) {
      if (this.nowBrandName) {
        http({
          instance: this,
          type: 'post',
          url: api.updateWithPlatformCategory,
          hasLoading: true,
          data: {
            brandId: value.brandId,
            partyId: value.partyId,
            brandName: this.nowBrandName,
            platform: '',
            platformBrandId: '',
            platformBrandName: '',
          },
          success: () => {
            this.$success(this.$t(`操作成功`))
            this.getDataList()
          },
        })
      } else {
        this.$warning(this.$t(`请输入`))
      }
    },
    setValue(value) {
      this.nowValue = value
      this.formData.platformBrandReqVO[this.nowValue]
      // this.brandList = []
      // this.editList = []
      // this.editList.push({
      //   brandId: this.formData.platformBrandReqVO[this.nowValue].platformBrandId,
      //   name: this.formData.platformBrandReqVO[this.nowValue].platformBrandName,
      // })
      // this.brandList.push(...this.editList)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    getDataList() {
      // brandOmsList
      http({
        instance: this,
        type: 'post',
        url: api.brandOmsList,
        hasLoading: true,
        data: {
          ...this.page,
        },
        success: (res) => {
          this.page.total = res.result.total
          this.page.pageNo = res.result.page_no
          this.page.pageSize = res.result.page_size
          //第一步,转化数据结构，通过changeList变为我们需要的数据格式
          let list = this.changeList([...res.result.list], res.result.platformList)
          //第二步，添加展开行，以及合并的判断值
          this.oldTable = this.addKey(list)
          this.tableData = this.addKey(list, 3)
        },
        error: () => {},
      })
    },
    changeList(list, platform) {
      let data = []
      list.forEach((item, index) => {
        let platformBrandNameList = []
        data.push({
          brandName: item.brandName,
          brandId: item.brandId,
          partyId: item.partyId,
          platformBrandNameList: [],
        })
        platform.forEach((otem) => {
          if (item.platformBrandMap[otem] && item.platformBrandMap[otem].name) {
            platformBrandNameList.unshift({
              platform: otem,
              platformBrandName: item.platformBrandMap[otem] && item.platformBrandMap[otem].name,
              platformBrandId: item.platformBrandMap[otem] && item.platformBrandMap[otem].brandId,
            })
          } else {
            platformBrandNameList.push({
              platform: otem,
              platformBrandName: item.platformBrandMap[otem] && item.platformBrandMap[otem].name,
              platformBrandId: item.platformBrandMap[otem] && item.platformBrandMap[otem].brandId,
            })
          }
        })
        data[index].platformBrandNameList = platformBrandNameList
      })
      return data
    },
    addKey(list, length) {
      let newTable = []
      list.forEach((item) => {
        if (item.platformBrandNameList.length > 3) {
          if (length) {
            item.platformBrandNameList.splice(length, item.platformBrandNameList.length)
          }
          item.platformBrandNameList.push({
            isOpen: 'false',
            platform: 'open',
            platformBrandName: '',
            platformBrandId: '',
            brandName: item.brandName,
            brandId: item.brandId,
          })
        }

        item.platformBrandNameList.forEach((otem, ondex) => {
          otem.brandName = item.brandName //添加OMS的类目名称
          otem.brandId = item.brandId //添加OMS的类目名称
          otem.partyId = item.partyId //添加OMS的类目名称
          otem.mergeColLength = 3 // 展开合并
          otem.mergeRowLength =
            length > item.platformBrandNameList.length
              ? item.platformBrandNameList.length
              : length + 1 // OMS类目名列表页合并
          otem.index = ondex //用来合并oms类目名的计算方式，这个为合并的依据
          // otem.platformBrandName = "";
          newTable.push(otem)
        })
      })
      return newTable
    },
    openData(value, type) {
      let arr = [] //保存当前需要展开的全部数据
      let endKey = 0
      let startKey = 0
      this.oldTable.forEach((item) => {
        if (item.brandId == value.brandId) {
          arr.push(item)
        }
      })
      this.tableData.forEach((item, index) => {
        if (item.brandId == value.brandId) {
          endKey = index
        }
      })
      arr.forEach((item, index) => {
        item.index = index
        item.mergeRowLength = arr.length
        item.mergeColLength = 3
      })

      if (type === 'false') {
        startKey = endKey - 3
        arr.forEach((item) => {
          if (item.isOpen === 'false') {
            item.isOpen = 'true'
          }
        })
        // 5为平台总数量 - 4 -折叠的1
        this.tableData.splice(startKey, 4)
        this.tableData.splice(startKey, 0, ...arr)
      } else {
        startKey = endKey + 1 - arr.length
        arr.forEach((item, index) => {
          item.index = index
          item.mergeRowLength = 4
          item.mergeColLength = 3
          if (item.isOpen === 'true') {
            item.isOpen = 'false'
          }
        })
        // 要先删除掉展开的所有行 才能去替换。不然arr.length已经变化了
        this.tableData.splice(startKey, arr.length)
        // 这里的4代表 展开 折叠 这一行的key  length -5 代表 需要删除的行数
        arr.splice(3, arr.length - 4)

        //用新的Arr替换掉原来空出来的位置
        this.tableData.splice(startKey, 0, ...arr)
      }
    },
    handleChangeTable(value) {
      this.params.platformBrandId = value
      this.brandList.forEach((item) => {
        if (item.brandId == value) {
          this.params.platformBrandName = item.name
        }
      })
    },
    submit() {},
    getBrandSelectList(value) {
      this.platform = value.platform || ''
    },
    add() {
      this.addVisible = true
      this.editType = false
    },
    edit(value) {
      this.params.platformBrandId = ''
      this.platformEdit = value.platform
      this.editType = true
      let newData = [...this.tableData]
      newData.forEach((item) => {
        if (item.brandId == value.brandId && item.platform == value.platform) {
          item.isEdit = true
        } else {
          item.isEdit = false
        }
      })
      this.tableData = newData
      this.fetchBrandList()
    },
    cancelTable(value) {
      this.editType = false
      let newData = [...this.tableData]
      newData.forEach((item) => {
        if (item.brandId == value.brandId && item.platform == value.platform) {
          item.isEdit = false
        }
      })
      this.tableData = newData
    },
    save(value) {
      let data = []
      this.formData.platformBrandReqVO.forEach((item) => {
        if (item.platformBrandId !== '') {
          data.push(item)
        }
      })
      this.params.brandId = value.brandId
      this.params.brandName = value.brandName
      this.params.platform = value.platform
      this.formData.platformBrandReqVOList = data
      //   createBrand
      const _self = this
      http({
        instance: _self,
        url: _self.editType ? api.upDateBrand : api.createBrand,
        type: 'post',
        data: _self.editType ? this.params : { ..._self.formData },
        hasLoading: false,
        success(res) {
          if (res.code == '1000000') {
            message.success('创建成功')
            _self.addVisible = false
            _self.getDataList()
            _self.editType = false
          }
          //   _self.brandList = res.result.list;
        },
      })
    },
    cancel() {
      this.addVisible = false
      this.editType = false
    },
    handleChange(value) {
      this.formData.platformBrandReqVO[this.nowValue].platformBrandId = value

      this.brandList.forEach((item) => {
        if (item.brandId == value) {
          this.formData.platformBrandReqVO[this.nowValue].platformBrandName = item.brandName
        }
      })
    },
    changeSize(current, size) {
      this.page.pageSize = size
      this.page.pageNo = 1
      this.getDataList()
    },
    changeNo(page) {
      this.page.pageNo = page
      this.getDataList()
    },
    fetchBrandList(value) {
      const _self = this
      let platform = this.platformEdit || _self.formData.platformBrandReqVO[_self.nowValue].platform
      try {
        http({
          instance: _self,
          url: api.queryLazadaBrand,
          type: 'post',
          data: {
            name: value,
            pageNo: 1,
            pageSize: 50,
            platform: platform,
            // platform: _self.platform,
          },
          hasLoading: false,
          success(res) {
            _self.brandList = res.result.list
            let arr = []
            for (let i of _self.brandList) {
              arr.push(i.brandId)
            }

            if (_self.editList.length > 0 && arr.indexOf[_self.editList[0].brandId] > -1) {
              _self.brandList.push(..._self.editList)
            }
          },
        })
        // }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-content {
  width: 100%;
}
::v-deep .ant-form-item-label {
  width: 120px;
}
::v-deep .ant-form-item {
  width: 100%;
}
</style>
